import mock from '@src/@fake-db/mock'

// ** Utils
import { paginateArray } from '../../../@fake-db/utils'
import { invoiceData } from './invoice'
import './activity'
import './attachment'

// ------------------------------------------------
// GET: Return Invoice List
// ------------------------------------------------
mock.onGet('/app/invoices').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1, status = null, sort, sortColumn } = config
  /* eslint-enable */

  const dataAsc = invoiceData.items.sort((a, b) => {
    if (a[sortColumn]) {
      return a[sortColumn] < b[sortColumn] ? -1 : 1
    } else {
      const splitColumn = sortColumn.split('.')
      const columnA = a[splitColumn[0]][splitColumn[1]]
      const columnB = b[splitColumn[0]][splitColumn[1]]
      return columnA < columnB ? -1 : 1
    }
  })

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const queryLowered = q.toLowerCase()
  const filteredData = dataToFilter.filter(invoice => {
    if (String('paid').includes(queryLowered) && invoice.balance === 0) {
      return invoice.balance === 0
    } else {
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      return (
        String(invoice.id).toLowerCase().includes(queryLowered) ||
        String(invoice.total).toLowerCase().includes(queryLowered) ||
        String(invoice.balance).toLowerCase().includes(queryLowered) ||
        invoice.dueDate.toLowerCase().includes(queryLowered)
      )
    }
  })
  /* eslint-enable  */

  return [
    200,
    {
      allData: invoiceData.items,
      total: filteredData.length,
      items: filteredData.length <= perPage ? filteredData : paginateArray(filteredData, perPage, page),
    },
  ]
})

mock.onPut(new RegExp('/app/invoices/*')).reply(config => {
  const invoiceId = config.url.split('/').at(-1)
  // Get event id from URL

  const invoiceIndex = invoiceData.items.findIndex(t => t.id.toString() === invoiceId)

  invoiceData.items[invoiceIndex] = JSON.parse(config.data)
  console.log(invoiceIndex, invoiceData.items[invoiceIndex])
  const responseData = {
    success: true,
    data: invoiceData.items[invoiceIndex],
    // data: {
    //    data,
    //   // paymentDetails: {
    //   //   totalDue: '$12,110.55',
    //   //   bankName: 'American Bank',
    //   //   country: 'United States',
    //   //   iban: 'ETD95476213874685',
    //   //   swiftCode: 'BR91905',
    //   // },
    // },
  }
  return [200, responseData]
})

// ------------------------------------------------
// DELETE: Deletes Invoice
// ------------------------------------------------
mock.onDelete(new RegExp('/app/invoices/*')).reply(config => {
  // Get invoice id from URL
  const invoiceId = config.url.split('/').at(-1)

  const invoiceIndex = invoiceData.items.findIndex(t => t.id.toString() === invoiceId)
  invoiceData.items.splice(invoiceIndex, 1)

  return [200]
})

mock.onGet(new RegExp('/app/invoices/*')).reply(config => {
  // Get invoice id from URL
  const invoiceId = config.url.split('/').at(-1)

  const invoice = invoiceData.items.find(t => t.id.toString() === invoiceId)

  return [
    200,
    {
      success: true,
      data: invoice,
    },
  ]
})

// ------------------------------------------------
// GET: Return Clients
// ------------------------------------------------
mock.onGet('/app/invoices/clients').reply(() => {
  const clients = invoiceData.items.map(invoice => invoice.client)
  return [200, clients.slice(0, 5)]
})
